<template>
  <div class="my-1 flexb fjustify-start falign-center">
    <div class="px-1" style="">
      <i v-i:duo#graduation-cap#20></i>
    </div>
    <div class="px-1 f-sanspro fs-8pt fw-600 mt-1">
      {{uc.ucCOD}} {{uc.ucSGL}} {{uc.codigo}}
    </div>
    <div class="fs-10pt me-3 f-raleway px-1 item-truncated pt-1">
      <v-tooltip bottom content-class="px-1 py-0 f-sanspro fw-600" open-delay="1200">
        <template v-slot:activator="{ on, attrs }">
          <v-card flat class="text-truncate" v-bind="attrs" v-on="on" @click="$emit('ucClick',uc)">{{uc.nome}}</v-card>
        </template>
        <span class="fs-8pt">{{uc.nome}}</span>
      </v-tooltip>
    </div>
    <div class="m-0 mt-n1 p-0" style="" v-if="uc != null">
      <span v-for="(area, key, index) in uc.areas" :key="key">
        <tagarea class="me-1" :sigla="area.sigla"/>
      </span>
    </div>
  </div>
</template>

<script>
import { rdb, snapshotValToArray } from '@/firebasedb.js';
import tagarea from "@/views/cursos/tagArea.vue";
import editobject from "./edit-object.vue"

export default {
  name: "ucitem",
  components: { tagarea, editobject },
  props: {
    crud: { default: true, type: Boolean },
    uc: { default: null, type: Object },
    area: { default: null, type: Object }
  },
  computed: {},
  data(){
    return {
      loading: false,
      refresh: 0,
      ucObj: null,
    }
  },
  watch: {
    uc() {
      this.ucObj = this.uc;
    }
  },
  mounted: function() {
    var self = this;
    self.ucObj = self.uc;
    //self.loading = true;
    //rdb.ref('').on('value',function(snapshot) {
    //  self.algo = snapshot.val();
    //  console.log("self.algo",self.algo);
    //  self.build();
    //});
  },
  methods:{

    build() {
      var self = this;
    },

    onSave(obj) {
      this.$emit('onSave',obj);
    }
  }
}
</script>

<style scoped>

</style>
