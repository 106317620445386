<template>
  <div class="" v-if="uc != null">
    <div class="mt-1">
      <div class="ms-3 me-2 flexb fjustify-start falign-center">
        <div class="flex1">
          <span class="f-sanspro fw-600">{{Object.keys(metas).length}}</span>
          <span class="ms-1 f-sanspro">metas</span>
        </div>
        <div class="flex0 border text-right">
        </div>
      </div>
    </div>
    <div class="me-4 mt-2" v-if="uc != null">
      <v-expansion-panels class="px-3 pt-2 pb-0 mt-2" v-bg:b>
        <v-expansion-panel class="mt-1 mb-3 p-0" v-for="(meta,key,index) in metas" :key="key" v-bg:b>
          <v-expansion-panel-header class="m-0 ps-0 pe-2 py-0" :color="hexTints(cor.active.b,.5)">
            <v-container fluid>
              <v-row align="center">
                <v-col cols="11">
                  <p class="p-0 m-0 f-lato fs-10pt fw-300 text-justify">
                    <span class="me-1 f-roboto fsp-0 fw-600 fs-7pt" v-cHex="'#808080'">id:</span>
                    <span class="f-roboto fsp-0 fw-600 fs-8pt" v-cHex="'#404040'">{{meta.metaCodigo}}</span>
                    {{meta.texto}}
                  </p>
                </v-col>
                <v-col cols="1">
                  <p class="p-0 m-0 f-lato fs-10pt fw-300 text-justify" v-if="meta.qtdeEncomendas != undefined">
                    {{meta.qtdeEncomendas}}
                  </p>
                </v-col>
              </v-row>
            </v-container>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="p-0 mb-n4 ms-n6 me-n6">
            <v-card flat class="m-0 p-0 mt-n2 pb-1" :color="hexTints(cor.active.b,.5)">
              <div class="me-2 text-right">
                <v-btn class="text-nonecase" color="" x-small @click="$refs.promptdialogref.show(meta)">Distribuir encomendas</v-btn>
              </div>
            </v-card>
            <v-card class="m-0 my-1 p-0" v-for="(item,key,index) in meta.encomendas" :key="key+refresh">
              <v-container fluid class="">
                <v-row align="center" class="">
                  <v-col cols="9" class="m-0 p-0 px-2">
                    <professoritem :userID="item[0].profID" :oferta="item[0]" :key="key+refresh"/>
                  </v-col>
                  <v-col cols="1">
                    <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn color="" small icon v-bind="attrs" v-on="on">
                          <i v-i:ic#switch-account#24></i>
                        </v-btn>
                      </template>
                      <v-list dense>
                        <v-list-item class="px-2 m-0 h24 text-center" v-for="(meta, index) in metas" :key="index" style="min-height: 0px;">
                          <v-btn class="p-0 m-0" x-small text @click="trocarMeta({item: item, meta: meta})">
                            <span class="fs-8pt f-lato fw-800">{{ meta.metaCodigo }}</span>
                          </v-btn>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-col>
                  <v-col cols="2">
                    <p class="p-0 m-0 f-lato fs-10pt fw-300 text-justify">
                      {{item.length}} itens
                    </p>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
    <promptdialog ref="promptdialogref" @confirm="distribuirEncomendas"/>
  </div>
</template>

<script>
import { rdb, snapshotValToArray } from '@/firebasedb.js';
import vueJsonEditor from 'vue-json-editor'
import editobject from "./edit-object.vue"
import editobjectbtn from "./edit-object-btn.vue"
import professoritem from "./professor-item.vue"
import promptdialog from "./promptdialog.vue"

export default {
  name: "",
  components: { vueJsonEditor, editobject, editobjectbtn, professoritem, promptdialog },
  props: {
    uc: { default: null, type: Object }
  },
  computed: {},
  data(){
    return {
      loading: false,
      refresh: 0,
      metas: {},
      ucIDToMove: "",
    }
  },
  watch: {},
  mounted: function() {
    var self = this;
    self.loading = true;
    if(this.uc != null) {
      rdb.ref('/curriculo/avaliacao/2021S2/metasIndicadasFinal').orderByChild("ucID").equalTo(this.uc.id).on('value',function(snapshot) {
        var snap = snapshot.val();
        self.metas = {};
        if(snap != null) {
          self.metas = snap;
        }
        console.log("self.metas",self.metas);
        self.build();
      });
    }
  },
  methods:{

    build() {
      var self = this;
      console.log("build");
      //rdb.ref('/curriculo/avaliacao/2021S2/encomendasA2').orderByChild("profID").equalTo("-MeqQvNXPQHLFPoJPKOr").on('value',function(snapshot) {
      //  console.log(snapshot.val());
      //});
      for(var key in self.metas) {
        var meta = self.metas[key];
        rdb.ref('/curriculo/avaliacao/2021S2/encomendasA2').orderByChild("meta").equalTo(meta.id).on('value',function(snapshot) {
          var snap = snapshot.val();
          console.log("snap",snap);
          if(snap != null) {
            console.log("meta.id "+meta.id,snap);
            var encomendas = {};
            var qtdeEncomendas = 0;
            for(var idx in snap) {
              qtdeEncomendas++;
              if(encomendas[snap[idx].profID] == undefined) {
                encomendas[snap[idx].profID] = [];
              }
              encomendas[snap[idx].profID].push(snap[idx]);
            }
            meta.encomendas = encomendas;
            meta.qtdeEncomendas = qtdeEncomendas;
          } else {
            console.log("meta sem encomenda"+meta.id,meta);
            meta.encomendas = {};
          }
          self.refresh++;
        });
      }

    },

    onJsonChange (value) {
      console.log('value:', value)
    },

    onSave(obj) {
      obj = clone2(obj);
      console.log("salvar",obj);
      var updates = {};
      updates["/curriculo/metas/"+obj.id] = obj;
      console.log("updates",updates);
      rdb.ref().update(updates);
      console.log("Agora já elvis!!!!");

    },

    moverParaUC(obj) {
      obj = clone2(obj);
      console.log("moverParaUC",obj);
    },

    confirm() {

    },

    vetSortByKey(vet2, sortKey, desc = false) {
      var vet = clone(vet2);
      function compare( a, b ) {
        if ( a[sortKey] < b[sortKey] ){
          return (desc ? 1 : -1);
        }
        if ( a[sortKey] > b[sortKey] ){
          return (desc ? -1 : 1);
        }
        return 0;
      }
      vet.sort( compare );
      //console.log(vet)
      return vet;
    },

    distribuirEncomendas(meta) {
      var self = this;
      console.log("distribuirEncomendas(meta)",meta);
      console.log("self.metas",self.metas);

      var metas = [];
      for(var key in self.metas) {
        if(key != meta.id) {
          metas.push(clone2(self.metas[key]));
        }
      }
      console.log("metas",metas);
      metas = this.vetSortByKey(metas,"qtdeEncomendas");
      console.log("metas",metas);

      var encomendas = [];
      for(var idx in meta.encomendas) {
        var encomendasProf = meta.encomendas[idx];
        for(var idx2 in encomendasProf) {
          encomendas.push(encomendasProf[idx2]);
        }
      }
      console.log("encomendas",encomendas);
      var encomenda = null;
      var updates = {};
      while(encomendas.length > 0) {
        for(var idx in metas) {
          encomenda = encomendas.pop();
          updates["/curriculo/avaliacao/2021S2/encomendasA2/"+encomenda.id+"/meta"] = metas[idx].id;
          if(encomendas.length == 0) break;
        }
      }
      updates["/curriculo/avaliacao/2021S2/metasIndicadasFinal/"+meta.id] = null;
      console.log("updates",updates);
      rdb.ref().update(updates);
      console.log("Agora já Elvis!!!!");
    },

    trocarMeta(item) {
      var self = this;
      console.log("trocarMeta(item)",item);
      console.log("self.metas",self.metas);

      var updates = {};
      var itens = item.item;
      for(var idx in itens) {
        updates["/curriculo/avaliacao/2021S2/encomendasA2/"+itens[idx].id+"/meta"] = item.meta.id;
      }

      console.log("updates",updates);
      rdb.ref().update(updates);
      console.log("Agora já Elvis!!!!");
      this.build();
    }


  }
}
</script>

<style scoped>
</style>
