<template>
  <span>
    <v-btn class="f-sanspro fs-10pt text-nonecase" color="error" outlined x-small @click="dialog = true">
      <i v-i:duo#pencil#16 v-c:D class="mt-n1 me-1"></i>
      {{label}}
    </v-btn>

    <v-dialog v-model="dialog" width="600">
      <v-card class="p-1">
        <div class="m-1 p-1">
          <vue-json-editor ref="jsoneditor" v-model="objData" :show-btns="false" :expandedOnStart="false" @json-change="onJsonChange"></vue-json-editor>
          <v-row>
            <v-col class="mt-2 text-center">
              <v-btn class="me-2" color="primary" outlined x-small @click="dialog = false">
                cancelar
              </v-btn>
              <v-btn class="" color="error" x-small @click="$emit('onSave',objData); dialog = false;">
                salvar
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
import { rdb, snapshotValToArray } from '@/firebasedb.js';
import vueJsonEditor from 'vue-json-editor'

export default {
  name: "",
  components: { vueJsonEditor },
  props: {
    obj: { default: null, type: Object },
    label: { default: "botão", type: String },
  },
  computed: {},
  data(){
    return {
      dialog: false,
      loading: false,
      refresh: 0,
      objData: null,
    }
  },
  watch: {
    obj() {
      this.objData = clone2(this.obj);
    }
  },
  mounted: function() {
    var self = this;
    this.objData = clone2(this.obj);
    //self.loading = true;
    //rdb.ref('').on('value',function(snapshot) {
    //  self.algo = snapshot.val();
    //  console.log("self.algo",self.algo);
    //  self.build();
    //});
  },
  methods:{

    build() {
      var self = this;
    },

    onJsonChange (value) {
      console.log('value:', value)
    },

  }
}
</script>

<style scoped>
</style>
