<template>
  <v-dialog v-model="dialog" width="300">
    <v-card>
      <v-card-title class="red darken-4 text-white fs-13pt fw-500 f-raleway py-1">
        Confirmação:
      </v-card-title>
      <v-card-text class="mt-3 mb-0 py-0">
        Deseja realmente <span class="fw-700">DISTRIBUIR</span> as encomendas nas demais metas?
      </v-card-text>
      <v-divider class="mb-0"></v-divider>
      <v-card-actions class="mt-1 py-1">
        <v-spacer></v-spacer>
        <v-btn :color="cor.active.I" text @click="dialog = false">
          cancelar
        </v-btn>
        <v-btn :color="cor.active.D" text @click="confirm">
          distribuir
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: "promptdialog",
  props: [ "msg" ],
  components: { },
  data() {
    return {
      dialog: false,
      item: null,
    }
  },
  computed: {},
  watch: {
  },
  mounted: function() {
    var self = this;
    this.log("Mounted");
  },

  methods:{

    show(item) {
      this.dialog = true;
      this.item = item;
    },

    confirm() {
      console.log("confirm");
      this.dialog = false;
      this.$emit('confirm',this.item);
    }
  }
}
</script>

<style scoped>

</style>
